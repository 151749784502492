
























import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { ProjectSearchResultCompanyModel } from "@/core/models";

export class Type {}

@Component({
  components: {},
})
export default class LabeledCompanyCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) label: string;
  @Prop({ default: false }) icon: string;
  @Prop({ default: false }) companies: ProjectSearchResultCompanyModel[];

  get isLoading() {
    return this.loading;
  }

  async created() {}

  async mounted() {}
}
