




























































import AppVue from "@/AppVue.vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class HelpPanel extends AppVue {
  @Prop({ default: "genericHelp" }) context: string;
}
